import { useEffect, useState, useContext, ClipboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@mui/material';
import AlertsContext, { Severity } from 'contexts/alerts/context';
import ChatsContext from 'contexts/chats/context';
import { ALLOWED_FILE_TYPES } from 'views/home/AttachFile/constants';
import useAttachFile from 'views/home/AttachFile/useAttachFile';
import { SendMessage } from 'views/home/Chat/hooks/useChat';
import useInputMessage from 'views/home/Chat/InputBar/InputMessage/useInputMessage';
import { defaultErrorKey, errorKeys } from 'views/home/AttachFile';
import ModalError from 'components/ErrorModal';
import { MainContainer, DropAreaContainer, DropAreaContent, DropAreaMessage } from './styles';

interface DropAreaProps {
  children: React.ReactNode;
  isDiscussion: boolean;
  sendMessage: SendMessage;
}

const openEvent = 'dragenter';
const titleKey = 'errorUploadFileTitle';

const DropArea = ({ children, isDiscussion, sendMessage }: DropAreaProps) => {
  const { t } = useTranslation();
  const { addAlert } = useContext(AlertsContext);
  const { discussionId } = useContext(ChatsContext);
  const { attachFile, status } = useAttachFile(sendMessage);
  const { disabledInputChat } = useInputMessage(discussionId, isDiscussion);
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const messageKey = errorKeys[status] || defaultErrorKey;
  const className = ['drop-area', open ? 'open' : '', disabledInputChat ? 'disabled' : ''].join(' ');

  const handleDragEvent = (event: React.DragEvent<HTMLDivElement>) => {
    const { type } = event;
    setOpen(type === openEvent);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault(); // prevent default to allow drop
  };

  const attachFiles = (files: FileList): boolean => {
    setOpen(false);
    if (disabledInputChat) {
      addAlert({
        message: t('warningDisabledInputChat'),
        severity: Severity.WARNING,
      });

      return false;
    }

    if (files.length && !disabledInputChat) {
      const validFiles = Array.from(files).filter(({ name }) => {
        const extension = name.split('.').pop() || '';
        const isValid = ALLOWED_FILE_TYPES.includes(extension);
        if (!isValid) {
          addAlert({
            message: t('errorInvalidFileType', { name }),
            severity: Severity.WARNING,
          });
        }
        return isValid;
      });

      const dataTransfer = new DataTransfer();
      validFiles.forEach((file) => dataTransfer.items.add(file));
      attachFile(dataTransfer.files);

      return Boolean(validFiles.length);
    }

    return false;
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const { files } = event.dataTransfer;
    attachFiles(files);
  };

  const handlePaste = (event: ClipboardEvent) => {
    if (attachFiles(event.clipboardData.files)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const isSuccessful = status >= 200 && status < 300;
    setOpenError(!isSuccessful);
  }, [status]);

  useEffect(() => {
    const preventDefault = (event: Event) => event.preventDefault();
    window.addEventListener('dragover', preventDefault);
    window.addEventListener('drop', preventDefault);
    return () => {
      window.removeEventListener('dragover', preventDefault);
      window.removeEventListener('drop', preventDefault);
    };
  }, []);

  return (
    <MainContainer onDragEnter={handleDragEvent} onPaste={handlePaste}>
      {children}
      <DropAreaContainer
        className={className}
        onDragEnter={handleDragEvent}
        onDragLeave={handleDragEvent}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <DropAreaContent>
          <DropAreaMessage>
            <Icon className="fa-regular fa-file-arrow-up" fontSize="inherit" color="inherit" />
            {t('uploadFile')}
          </DropAreaMessage>
        </DropAreaContent>
      </DropAreaContainer>
      <ModalError
        handleClose={() => setOpenError(false)}
        title={t(titleKey)}
        message={t(messageKey)}
        open={openError}
      />
    </MainContainer>
  );
};

export default DropArea;
