/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import React, { useCallback, useState, useEffect, useContext } from 'react';

import { Stack, Typography, Icon } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Tooltip } from '@engyalo/design-system';
import { RowsPerPage } from '../../../constants/pagination';

import DepartmentContext from '../../../contexts/departments/context';
import ConversationContext from '../../../contexts/conversation/context';
import { getVisitorByTerm } from '../../../services/getVisitorByTerm';
import { IOrder } from '../../../types/order';
import ChatsContext from '../../../contexts/chats/context';
import { CustomChip } from '../../../components/Tags/style';
import { tagsColor } from '../../../helpers/tagsColors';
import { IMessageDetails } from './types';
import { replaceFieldsTable } from '../../../helpers/replaceFieldsTable';
import { convertDatesTable, convertToLocaleDateString } from '../../../helpers/formatterDates';
import { getVerifiedTemplates } from '../../../services/getVerifiedTemplates';
import { getVerifiedTemplateFullInfo } from '../../../services/getVerifiedTemplateFullInfo';
import AuthContext from '../../../contexts/auth/context';
import { sendNotification } from '../../../services/sendNotification';
import { getNotificationHistory } from '../../../services/getNotificationHistory';
import DrawerContext from '../../../contexts/drawer/context';
import { Template, TemplateInfo, Visitor, Notification } from '../../../services/types';
import { createKeyToken } from '../../../helpers/createKeyToken';
import { SortVerifiedTemplates } from '../../ServiceManagement/VerifiedTemplatesManager/useVerifiedTemplatesManager';
import { STORAGE_KEYS, SESSION_STORAGE_KEYS } from '../../../constants/defaultValues';
import { SortDirection } from '../../../constants';
import { flag, useFeatureFlag } from '../../../hooks/useFeatureFlag';

const DEFAULT_BOT_ID = 'delivery';

enum SortContactConversation {
  name = 'name',
  phone = 'phone',
  lastChat = 'lastchat',
  tags = 'livechatData.tags',
}

enum SortTemplateHistory {
  template = 'template.name',
  dateSent = 'CreatedAt',
  contact = 'contact.name',
}

const checkTitle = (showHistory: boolean, contactName?: string, templateName?: string) => {
  if (contactName && templateName && !showHistory) {
    return 'messageDetailsTitle';
  }
  if (templateName && !showHistory) {
    return 'selectContact';
  }
  if (showHistory) {
    return 'activeMessageHistory';
  }
  return 'startNewConversation';
};

const checkSubtitle = (showHistory: boolean, contactName?: string, templateName?: string) => {
  if (contactName && templateName && !showHistory) {
    return 'messageDetailsSubtitle';
  }
  if (templateName && !showHistory) {
    return 'selectContactSubtitle';
  }
  if (showHistory) {
    return 'activeMessageHistorySubtitle';
  }

  return 'newConversationSubtitle';
};

const checkTooltip = (showHistory: boolean, contactName?: string, templateName?: string) => {
  if (contactName && templateName && !showHistory) {
    return 'templateTooltip';
  }
  if (templateName && !showHistory) {
    return 'selectContactTooltip';
  }

  return 'startNewConversationTooltip';
};

type FieldsToFill = {
  field: string;
  value: string;
};

const useConversation = () => {
  const { t } = useTranslation();
  const shouldGetTemplatesByDepartment = useFeatureFlag(flag.SHOULD_GET_TEMPLATES_BY_DEPARTMENT);
  const { currentUserInfo } = useContext(AuthContext);
  const { departments } = useContext(DepartmentContext);
  const { isMobile } = useContext(ChatsContext);
  const { loadUserInfo } = useContext(DrawerContext);
  const { handleSelectTemplate, templateSelected, handleSelectContact, contactSelected } =
    useContext(ConversationContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [headerColumns, setHeaderColumns] = useState<Array<string>>([]);
  const [tableRows, setTableRows] = useState<Array<any>>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [pagination, setPagination] = useState({ count: 100, offset: 0 });

  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [hsmSelectedData, setHsmSelectedData] = useState<TemplateInfo>();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showModalUnsavedChanges, setShowModalUnsavedChanges] = useState(false);
  const [errorUpdt, setErrorUpdt] = useState(false);
  const [orderContacts, setOrderContacts] = useState<IOrder>('desc');
  const [orderContactsBy, setOrderContactsBy] = useState('username');
  const languageLocalStorage = localStorage.getItem(STORAGE_KEYS.LANGUAGE);
  const [showPreviewMessage, setShowPreviewMessage] = useState(false);
  const [previewText, setPreviewText] = useState('');
  const [showSnackBarMsgSentSuccess, setShowSnackBarMsgSentSuccess] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [showModalSendAgain, setShowModalSendAgain] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [showHistoryData, setShowHistoryData] = useState(false);
  const [showDrawerCreateContact, setShowDrawerCreateContact] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorModalSendMessage, setShowErrorSendMessage] = useState(false);
  const [inputText, setInputText] = useState('');
  const [fieldsToFill, setFieldsToFill] = useState<FieldsToFill[]>([]);
  const [orderVerifiedHSMs, setOrderVerifiedHSMs] = useState<IOrder>('asc');
  const [orderByVerifiedHSMs, setOrderByVerifiedHSMs] = useState('');
  const botId = sessionStorage.getItem(SESSION_STORAGE_KEYS.PATH);
  const [showContactInformation, setShowContactInformation] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<IMessageDetails>({
    mode: 'onChange',
  });

  const currentLanguage = languageLocalStorage || window.navigator.language;

  const handleCurrentPage = useCallback((_: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);

    setPagination((old) => ({
      ...old,
      offset: (value - 1) * RowsPerPage,
    }));
  }, []);

  const getTags = (tagsData: string[] | string | undefined) => {
    const tags = typeof tagsData === 'string' && tagsData.split(';');

    if (tags && tags.length) {
      const listTagsToShow = tags
        .map((tag) => {
          const color = tagsColor(tag);
          const uniqueKey = createKeyToken();
          return <CustomChip style={{ marginRight: '8px' }} key={uniqueKey} label={tag} customcolor={color} />;
        })
        .slice(0, 1);
      if (tags.length > 1) {
        const uniqueKey = createKeyToken();
        listTagsToShow.push(<CustomChip key={uniqueKey} label={`+${tags.length - 1}`} />);
      }
      if (tags.length > 1) {
        return (
          <Tooltip
            title={
              <div style={{ textAlign: 'justify' }}>
                {tags.map((item: string) => {
                  const uniqueKey = createKeyToken();
                  return <div key={uniqueKey}>{item}</div>;
                })}
              </div>
            }
          >
            <div>{listTagsToShow}</div>
          </Tooltip>
        );
      }
      return listTagsToShow;
    }
  };

  const handleTable = (list: Notification[] | Template[] | Visitor[], tableType: string) => {
    const listValues = list;
    if (tableType === 'templates' || tableType === 'templatesHistory') {
      if (isMobile) {
        if (tableType === 'templates') {
          setHeaderColumns(['name', 'preview']);
        } else {
          setHeaderColumns(['contact', 'template', 'dateSent']);
        }
        const values = listValues.map((item: any) => {
          if (tableType === 'templates') {
            return {
              _id: item.ID,
              template: item.Name,
              preview: (
                <Typography
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '400px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: replaceFieldsTable(item.BodyText),
                  }}
                />
              ),
            };
          }
          const finalDate = convertDatesTable(item.CreatedAt, languageLocalStorage || 'pt-BR', 'templatesHistory');
          const auxFinalText = finalDate.text.split(' ');
          return {
            _id: item.Contact.id,
            contact: item.Contact.name,
            template: item.Template.name,
            dateSent: (
              <Typography whiteSpace="break-spaces">
                {typeof finalDate.time === 'number' && finalDate.text
                  ? `${finalDate.time} ${t(`${auxFinalText[0]}`)}`
                  : `${t(`${finalDate.date}`)}${t(`${auxFinalText[0]}`)} ${t(`${auxFinalText[1] || ''}`)}\n${
                      finalDate.time
                    }`}
              </Typography>
            ),
          };
        });
        setTableRows(values);
      } else {
        if (tableType === 'templates') {
          setHeaderColumns(['template', 'sharedWith', 'preview', 'dateAdded']);
        } else {
          setHeaderColumns(['contact', 'template', 'dateSent']);
        }

        const values = listValues.map((item: any) => {
          if (tableType === 'templates') {
            const finalDate = convertDatesTable(item.CreatedAt, languageLocalStorage || 'pt-BR', 'templates');
            const auxFinalText = finalDate.text.split(' ');

            return {
              _id: item.ID,
              template: item.Name,
              sharedWith: item.SharedWith.length ? (
                <Typography
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '300px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: item.SharedWith.map((el: any) => el.name).join(', '),
                  }}
                />
              ) : (
                ''
              ),
              preview: (
                <Typography
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '250px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: replaceFieldsTable(item.BodyText),
                  }}
                />
              ),
              dateAdded: (
                <Typography whiteSpace="break-spaces">
                  {typeof finalDate.time === 'number' && finalDate.text
                    ? `${finalDate.time} ${t(`${auxFinalText[0]}`)}`
                    : `${t(`${finalDate.date}`)}${t(`${auxFinalText[0]}`)} ${t(`${auxFinalText[1] || ''}`)}\n${t(
                        `${finalDate.time}`
                      )}`}
                </Typography>
              ),
            };
          }
          const finalDate = convertDatesTable(item.CreatedAt, languageLocalStorage || 'pt-BR', 'templatesHistory');

          const auxFinalText = finalDate.text.split(' ');

          return {
            _id: item.Contact.id,
            contact: item.Contact.name,
            template: item.Template.name,
            dateSent: (
              <Typography whiteSpace="break-spaces">
                {typeof finalDate.time === 'number' && finalDate.text
                  ? `${finalDate.time} ${t(`${auxFinalText[0]}`)}`
                  : `${t(`${finalDate.date}`)}${t(`${auxFinalText[0]}`)} ${t(`${auxFinalText[1] || ''}`)}\n${
                      finalDate.time
                    }`}
              </Typography>
            ),
          };
        });
        setTableRows(values);
      }
    } else if (isMobile) {
      setHeaderColumns(['name', 'lastChat']);
      const values = listValues.map((item: any) => {
        let auxTags = '';
        if (item.livechatData) {
          if (item.livechatData.tags) {
            auxTags = item.livechatData.tags.replaceAll(';', ',');
          }
        }

        return {
          _id: item._id,
          name: item.name,
          lastChat: item.lastChat ? convertToLocaleDateString(item.lastChat.ts, currentLanguage) : '-',
          tagsList: auxTags ?? '-',
        };
      });

      setTableRows(values);
    } else {
      setHeaderColumns(['name', 'phone', 'tags', 'lastChat']);
      const values = listValues.map((item: any) => {
        let auxTags = '';
        if (item.livechatData) {
          if (item.livechatData.tags) {
            auxTags = item.livechatData.tags.replaceAll(';', ',');
          }
        }

        return {
          _id: item._id,
          name: item.name,
          phone: item.phone ? (item.phone[0].phoneNumber === 'undefined' ? '-' : item.phone[0].phoneNumber) : '-',
          tags: getTags(item.livechatData?.tags ?? []),
          lastChat: item.lastChat ? convertToLocaleDateString(item.lastChat.ts, currentLanguage) : '-',
          tagsList: auxTags ?? '-',
        };
      });

      setTableRows(values);
    }
  };

  const loadHSMs = useCallback(
    async (term?: string, sortType: string = 'template', sortValue: number = 1, departmentId?: string) => {
      setLoading(true);

      try {
        setOrderByVerifiedHSMs(sortType ?? '');
        setOrderVerifiedHSMs(sortValue === SortDirection.ASC ? 'asc' : 'desc');
        let sortBy = sortType;
        const orderByLastNotificationDateSent = -1;
        if (showHistoryData) {
          sortBy = SortTemplateHistory[sortType as keyof typeof SortTemplateHistory];
        } else {
          sortBy = SortVerifiedTemplates[sortType as keyof typeof SortVerifiedTemplates];
        }

        const propsGetNotificationHistory = {
          Count: pagination.count,
          Offset: pagination.offset,
          accountId: botId || DEFAULT_BOT_ID,
          search: term || undefined,
          sort: sortBy,
          sortValue: showHistoryData ? orderByLastNotificationDateSent : sortValue,
        };
        if (showHistoryData) {
          const {
            data: { Notifications, Total },
          } = await getNotificationHistory(propsGetNotificationHistory);

          if (Total) {
            setTotalItems(Total);
            handleTable(Notifications, 'templatesHistory');
          }
        } else {
          const propsGetTemplates = {
            Count: pagination.count,
            Offset: pagination.offset,
            accountId: botId || DEFAULT_BOT_ID,
            search: term || undefined,
            sort: sortBy,
            sortValue,
            departmentsIds: departmentId || undefined,
            isActiveSalesdesk: true,
          };

          if (shouldGetTemplatesByDepartment) {
            propsGetTemplates.departmentsIds = departments.map((d) => d._id).join() || undefined;
          }

          const {
            data: { Templates, Total },
          } = await getVerifiedTemplates(propsGetTemplates);
          if (Total) {
            setTotalItems(Total);
            handleTable(Templates, 'templates');
          }
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        setShowErrorModal(true);
        console.log(error);
      }
    },
    [showHistoryData]
  );

  const loadContacts = useCallback(
    async (term?: string, sortType: string = 'name', sortValue: number = 1) => {
      setOrderContactsBy(sortType ?? '');
      setOrderContacts(sortValue === SortDirection.ASC ? 'asc' : 'desc');
      let sortBy = 'name';

      sortBy = SortContactConversation[sortType as keyof typeof SortContactConversation];

      const propsGetVisitorByTerm = {
        term,
        count: pagination.count,
        offset: pagination.offset,
        sort: sortBy,
        sortValue,
      };
      try {
        const {
          data: { visitors, total },
        } = await getVisitorByTerm(propsGetVisitorByTerm);

        setTotalItems(total);
        handleTable(visitors, 'contacts');
      } catch (error) {
        console.log(error);
      }
    },
    [templateSelected]
  );

  const getHSMSelected = async (id: string) => {
    setLoading(true);
    try {
      const { data } = await getVerifiedTemplateFullInfo(id);

      setHsmSelectedData(data);
      setFieldsToFill(data.Messages[0].Parameters?.map((el) => ({ field: el, value: '' })));
      setLoading(false);
    } catch {
      handleSelectTemplate();
      setLoading(false);
      setShowErrorModal(true);
    }
  };

  const handleClickItem = (item: any) => {
    if (templateSelected?.template) {
      handleSelectContact(item);
    } else if (showHistoryData) {
      loadUserInfo(item._id.trim());
      setShowContactInformation(true);
    } else {
      getHSMSelected(item._id);
      handleSelectTemplate(item);
    }
  };

  const handleChangeForm = (isChanged: boolean) => {
    setUnsavedChanges(isChanged);
  };

  const checkTitleAndSubtitle = useCallback(
    () => (
      <Stack>
        <Typography variant="h2" color="neutral.var90" display="flex" justifyContent="space-between">
          {t(checkTitle(showHistoryData, contactSelected?.name, templateSelected?.template))}

          {!contactSelected?.name && !templateSelected?.template && !showHistoryData && (
            <span>
              <Button
                data-testid="showHistory"
                variant="text"
                size="small"
                onClick={() => {
                  setShowHistoryData((state) => !state);
                }}
                startIcon={<i className="fa-solid fa-clock-rotate-left" />}
                sx={{ textTransform: 'capitalize' }}
              >
                {t('history')}
              </Button>
            </span>
          )}
        </Typography>
        <Typography variant="subtitle1" color="neutral.var70" marginTop="8px">
          {t(checkSubtitle(showHistoryData, contactSelected?.name, templateSelected?.template))}
          <Tooltip
            title={t(checkTooltip(showHistoryData, contactSelected?.name, templateSelected?.template))}
            placement="top"
          >
            <Icon
              className="fa-solid fa-circle-question"
              sx={{
                display: showHistoryData ? 'none' : '',
                color: 'neutral.var40',
                marginLeft: '5px',
                cursor: 'pointer',
                fontSize: '1.4rem',
              }}
            />
          </Tooltip>
        </Typography>
      </Stack>
    ),
    [t, contactSelected?.name, templateSelected?.template, showHistoryData]
  );

  const replaceVariable = (field: string, value: string) => {
    setFieldsToFill(
      fieldsToFill.map((el) => {
        if (el.field === field) {
          el.value = value;
        }
        return el;
      })
    );
  };

  const onSubmit: SubmitHandler<IMessageDetails> = async (form) => {
    try {
      const teamId = form.team._id;
      delete form.team;

      setLoadingRequest(true);

      await sendNotification(
        currentUserInfo._id,
        contactSelected?._id ?? '',
        templateSelected?._id ?? '',
        teamId,
        form
      );

      setShowPreviewMessage(false);
      setTotalItems(0);
      setShowHistoryData(true);
      setShowSnackBarMsgSentSuccess(true);
      setLoadingRequest(false);
    } catch (error) {
      setShowErrorSendMessage(true);
      setLoadingRequest(false);
    }
  };

  useEffect(() => {
    if (fieldsToFill && hsmSelectedData?.BodyText) {
      let auxPreview = hsmSelectedData?.BodyText;

      fieldsToFill.forEach((el) => {
        if (el.field && el.value) {
          auxPreview = auxPreview.replace(`{{${el.field}}}`, el.value);
        }
      });
      setPreviewText(auxPreview);
    }
  }, [fieldsToFill]);

  useEffect(() => {
    if (!templateSelected?.template) {
      reset();
      setInputText('');
    }
  }, [templateSelected]);

  useEffect(() => {
    const shouldShowPrevMessage = !!(hsmSelectedData?.ID && contactSelected?.name);

    setShowPreviewMessage(shouldShowPrevMessage);
  }, [hsmSelectedData, contactSelected]);

  useEffect(() => {
    if (inputText && templateSelected?.template && !contactSelected?.name) {
      loadContacts(inputText, orderContactsBy, orderContacts === 'desc' ? SortDirection.DESC : SortDirection.ASC);
    } else {
      if (!inputText && templateSelected?.template) {
        loadContacts();
        return;
      }
      if (showHistoryData) {
        loadHSMs(undefined, SortTemplateHistory.dateSent, -1);
        handleSelectContact(undefined);
        handleSelectTemplate(undefined);
        setHsmSelectedData(undefined);
        return;
      }
      loadHSMs(inputText);
    }
  }, [inputText, templateSelected, contactSelected, showHistoryData]);

  useEffect(() => {
    if (showHistoryData) {
      setHsmSelectedData(undefined);
    }
  }, [showHistoryData]);

  return {
    currentPage,
    headerColumns,
    tableRows,
    totalItems,
    handleCurrentPage,
    loading,
    handleClickItem,
    setShowDrawer,
    showDrawer,
    hsmSelectedData,
    departments,
    unsavedChanges,
    setUnsavedChanges,
    handleChangeForm,
    showModalUnsavedChanges,
    setShowModalUnsavedChanges,
    errorUpdt,
    setErrorUpdt,
    checkTitleAndSubtitle,
    showPreviewMessage,
    previewText,
    replaceVariable,
    handleSubmit,
    onSubmit,
    control,
    isDirty,
    showSnackBarMsgSentSuccess,
    loadingRequest,
    setShowSnackBarMsgSentSuccess,
    showModalSendAgain,
    setShowModalSendAgain,
    showModalError,
    setShowModalError,
    showHistoryData,
    setShowHistoryData,
    showDrawerCreateContact,
    setShowDrawerCreateContact,
    showErrorModal,
    setShowErrorModal,
    inputText,
    setInputText,
    orderContacts,
    orderContactsBy,
    loadContacts,
    orderVerifiedHSMs,
    orderByVerifiedHSMs,
    loadHSMs,
    showErrorModalSendMessage,
    setShowErrorSendMessage,
    showContactInformation,
    setShowContactInformation,
    setHsmSelectedData,
  };
};

export default useConversation;
