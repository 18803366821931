import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { samlRoutes } from '../../constants';
import { environmentUtils } from '../../utils';

export const SAMLLogin = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const instance = environmentUtils.getEnvironmentInstance();
    window.location.href = `${process.env.REACT_APP_BFF_API_URL}/v1/${instance}${samlRoutes.login}`;
  }, []);

  return <p>{t('samlRedirect')}</p>;
};
