/* eslint-disable react-hooks/exhaustive-deps */
// Disabling exhaustive-deps because the useEffects are dependent on the state of the component
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConversationExplorerV2 } from '@engyalo/conversation-explorer-ui';
import { getBotsByClient } from 'services/getBotsByClient';
import { logError } from 'services/Logger';
import { SESSION_STORAGE_KEYS } from 'constants/defaultValues';
import { environmentUtils } from 'utils';
import LoadingAnimation from 'components/AnimationLoading';
import AuthContext from 'contexts/auth/context';
import { LANGUAGES } from './ConversationExplorer.types';
import { LoadingWrapper } from './ConversationExplorer.styled';

export interface IConversationExplorerProps {
  phone?: string;
  startDate?: Date;
  endDate?: Date;
}

const ConversationExplorer = () => {
  const { i18n } = useTranslation();
  const [botList, setBotList] = useState<string[]>([]);
  const [botId, setBotId] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRenderConversationExplorer, setShouldRenderConversationExplorer] = useState(false);

  const { currentUserInfo } = useContext(AuthContext);

  // TODO: Remove this once components are updated to make this optional
  const jwtToken = '';
  const cleanLanguage = i18n.language.split('-')[0];
  const salesdeskBFFUrl = `${process.env.REACT_APP_BFF_API_URL}/v2/conversation-explorer`;

  const authToken = sessionStorage.getItem(SESSION_STORAGE_KEYS.AUTH_TOKEN) || '';
  const userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.USER_ID) || '';
  const instance = environmentUtils.getEnvironmentInstance();

  const rocketChatHeaders = {
    token: authToken,
    userId,
    instance,
  };

  const getBots = async () => {
    setIsLoading(true);
    try {
      const { data } = await getBotsByClient();
      if (data.length === 0) {
        setIsLoading(false);
        setBotList([]);
        return;
      }
      if (data.length === 1) {
        setIsLoading(false);
        setBotId(data[0].botID);
        return;
      }
      setBotList(data.map((bot) => bot.botID));
    } catch (error) {
      logError('Error fetching bots', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getBots();
  }, []);

  useEffect(() => {
    setShouldRenderConversationExplorer((botList.length > 0 || botId !== undefined) && !isLoading);
  }, [botList, botId, isLoading]);

  return (
    <>
      {isLoading && (
        <LoadingWrapper>
          <LoadingAnimation size={40} />
        </LoadingWrapper>
      )}
      {!isLoading && shouldRenderConversationExplorer && (
        <ConversationExplorerV2
          rocketChatHeaders={rocketChatHeaders}
          jwtToken={jwtToken}
          apiUrl={salesdeskBFFUrl}
          botslug={botId}
          language={cleanLanguage as LANGUAGES}
          padding="4rem"
          bots={botList}
          client="salesdesk"
          userEmail={currentUserInfo.email}
        />
      )}
    </>
  );
};

export default ConversationExplorer;
