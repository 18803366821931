import { useMemo, useState } from 'react';
import { BROWSER_NOTIFICATION } from '../constants/defaultValues';
import { INotification } from '../contexts/websocket/types';
import { NotificationType } from '../contexts/websocket/provider';
import { logError } from '../services/Logger';
import useEnvironment from './useEnvironments';

const useNotification = () => {
  const { getBasePath, getInstance } = useEnvironment();
  const instance = useMemo(() => getInstance(), [getInstance]);
  const basePath = useMemo(
    () => (process.env.REACT_APP_EPHEMERAL ? getBasePath().replace(instance, '') : '/'),
    [getBasePath, instance]
  );

  const [isDocumentHidden, setIsDocumentHidden] = useState(false);
  const [isWindowBlurred, setIsWindowBlurred] = useState(false);
  const notificationVolume = parseInt(localStorage.getItem(BROWSER_NOTIFICATION.SOUND_VOLUME) || '', 10);
  const newRoomNotificationSound = localStorage.getItem(BROWSER_NOTIFICATION.NEW_ROOM_NOTIFICATION_SOUND);
  const newMessageNotificationSound = localStorage.getItem(BROWSER_NOTIFICATION.NEW_MESSAGE_NOTIFICATION_SOUND);

  document.addEventListener('visibilitychange', () => setIsDocumentHidden(document.hidden));
  window.addEventListener('blur', () => setIsWindowBlurred(true));
  window.addEventListener('focus', () => setIsWindowBlurred(false));

  const checkPermission = () => {
    if (!('Notification' in window)) {
      console.info('Este browser não suporta notificações de Desktop');
      return false;
    }
    if (Notification.permission === 'granted') {
      return true;
    }
    if (Notification.permission !== 'denied') {
      Notification.requestPermission((permission) => {
        if (permission === 'granted') {
          return true;
        }
        return false;
      });
    }
  };

  const playNotification = (title: string, description: string, type: string) => {
    if (notificationVolume) {
      const notification = new Notification(title, {
        body: description,
        silent: notificationVolume === 0,
        icon: `${basePath}favicon.png`,
      });

      notification.addEventListener('error', (event) => {
        logError('Failed to send desktop notification', null, { event });
      });

      notification.addEventListener('click', () => window.focus());

      const audio = new Audio(
        `${basePath}sounds/${type === 'room' ? newRoomNotificationSound : newMessageNotificationSound}.mp3`
      );

      if (notificationVolume !== 0) {
        audio.play();
      }
    }
  };

  const notify = (notificationData: INotification) => {
    if (!notificationData || !checkPermission()) {
      return;
    }

    const { title, description, chatId, type } = notificationData;
    if (isWindowBlurred || isDocumentHidden) {
      const isRoomNotification = chatId === undefined || type === NotificationType.NEW_CHAT;
      playNotification(title, description, isRoomNotification ? 'room' : 'message');
    }
  };

  return {
    notify,
  };
};

export default useNotification;
