import { getUnits } from '../../../services/getUnits';
import { getDepartments } from '../../../services/getDepartments';
import { getDepartmentsListUnit } from '../../../services/getDepartmentsUnit';
import { SESSION_STORAGE_KEYS } from '../../../constants/defaultValues';
import { IDepartments, ITeamsDashboardAnalytics } from './useServiceDashboard';

export const loadTeams = async () => {
  const {
    data: { departments },
  } = await getDepartments();
  const userDepartments = departments;
  const {
    data: { units },
  } = await getUnits();
  const teamsList: IDepartments[] = [];

  await units.reduce(async (teamsPromise, element) => {
    await teamsPromise;

    const unit = element;
    if (unit.numDepartments) {
      const { data } = await getDepartmentsListUnit(unit._id);
      const list = data.departments;
      teamsList.push(...list);
    }
  }, Promise.resolve());

  const finalTeams: ITeamsDashboardAnalytics[] = [];

  for (const userDepartment of userDepartments) {
    for (const { _id, name } of teamsList) {
      if (_id === userDepartment._id && name !== 'bot') {
        finalTeams.push({ _id, name });
      }
    }
  }

  const sessionStorageSelectedTeams = sessionStorage.getItem(SESSION_STORAGE_KEYS.DASHBOARD_SELECTED_TEAMS);
  let previousSelectedTeam: string[] = sessionStorageSelectedTeams ? JSON.parse(sessionStorageSelectedTeams) : [];
  if (!previousSelectedTeam.length) {
    previousSelectedTeam = [finalTeams[0]._id];
    sessionStorage.setItem(SESSION_STORAGE_KEYS.DASHBOARD_SELECTED_TEAMS, JSON.stringify([finalTeams[0]._id]));
  }

  return { previousSelectedTeam, finalTeams };
};
