import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../contexts/auth/context';
import usePermissions from '../hooks/usePermissions';
import { IPrivateRouter } from './types';

const PrivateRoute = ({ children, permission }: IPrivateRouter) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { checkPermission } = usePermissions();

  const handleAuthetication = () => {
    const isAuthenticatedUser = isAuthenticated();

    if (isAuthenticatedUser && !permission) {
      return children;
    }
    if (permission && isAuthenticatedUser && checkPermission(permission)) {
      return children;
    }
    return permission && isAuthenticatedUser ? <Navigate to="/" /> : <Navigate to="/login" />;
  };
  return handleAuthetication();
};
export default PrivateRoute;
