/* eslint no-underscore-dangle: ["error", { "allow": ["_id", "_updatedAt"] }] */
import { resumeOnHold } from 'services/resumeOnHold';
import { logError, logInfo } from 'services/Logger';
import { SnackbarType } from 'components/Snackbar';
import React, { useState, useRef, useEffect, useContext, useMemo, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { putChatOnHoldService } from 'services/putChatOnHold';
import { sendEmptyMessageFromUser } from 'services/sendEmptyMessageFromUser';
import WebsocketContext from './context';
import { MessageResponse, MessageType } from '../../types/message';
import { SubscribeType } from '../../types/subscribe';
import {
  IDiscussionNotification,
  IDiscussionNotificationContent,
  INotification,
  IResultResponse,
  IRoomType,
} from './types';
import useEnvironment from '../../hooks/useEnvironments';
import AuthContext from '../auth/context';
import useSegment from '../../services/Analytics';
import { closeChat } from '../../services/closeChat';
import { SESSION_STORAGE_KEYS, WEBSOCKET_EVENT_LOG_MESSAGE } from '../../constants/defaultValues';

export const closeChatInstance = async (
  rid: string,
  botId: string,
  tags: string[],
  phone: string,
  commentCloseChat?: string
) => {
  const data = await closeChat(rid, botId, tags, phone, commentCloseChat);
  return data;
};

const pingInterval = 5000; // Ping interval in milliseconds
const pingTimeout = 3000; // Timeout in milliseconds

const MIN_RECONNECTION_DELAY_TIME = 1000; // time in milliseconds
const MIN_UP_TIME = 100; // time in milliseconds

const PRIVATE_GROUP = 'p';
const SUBSCRIPTIONS_CHANGED_EVENT = 'subscriptions-changed';

interface PendingRequestItem {
  id: number;
}

const isPendingRequestItem = (item: unknown): item is PendingRequestItem =>
  item !== null && typeof item === 'object' && 'id' in item;

const isPendingRequestIndex = (index: unknown): index is number => typeof index === 'number';

export enum NotificationType {
  NEW_CHAT = 'newChat',
  NEW_MESSAGE = 'newMessage',
  DISCUSSION = 'discussion',
  CHAT_CLOSED = 'chatClosed',
  CHAT_TAKEN = 'chatTaken',
}

const WebsocketProvider: React.FC = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getUrl, getInstance } = useMemo(useEnvironment, [useEnvironment]);
  const location = useLocation();
  const { currentUserInfo, updateAvatarURL, networkErrorFound, setNetworkErrorFound } = useContext(AuthContext);

  const { children } = props;
  const [messagesList, setMessagesList] = useState<MessageType[]>([]);
  const [messagesListDiscussion, setMessagesListDiscussion] = useState<MessageType[]>([]);
  const [notification, originalSetNotification] = useState<INotification | null>(null);

  // TODO: identify why we're setting the same notification more than once to avoid this workaround. Consider:
  // a) Unnecesary re-renders due to missing effect dependencies.
  // b) Are similar messages coming through the socket and we need to better identify their structure? e.g. read/unread
  const setNotification = (newNotification: INotification) => {
    originalSetNotification((oldNotification) => {
      if (oldNotification?.chatId !== newNotification.chatId || oldNotification?.type !== newNotification.type) {
        return newNotification;
      }

      return oldNotification;
    });
  };

  const [websocketIsConnect, setWebsocketIsConnect] = useState(false);
  const [websocketHasConnected, setWebsocketHasConnected] = useState(false);
  const [pendingRequests, setPendingRequests] = useState<Array<unknown>>([]);
  const [subscribeIdMessages, setSubscribeIdMessages] = useState('');
  const [subscribeIdMessagesDiscussion, setSubscribeIdMessagesDiscussion] = useState('');
  const [requestTransferId, setRequestTransferId] = useState('');
  const [resultTransferRequest, setResultTransferRequest] = useState<IResultResponse>();

  const [resultMethodCalls, setResultMethodCalls] = useState<IResultResponse>();

  const [subscribeNotification, setSubscribeNotification] = useState<SubscribeType>();
  const divRef = useRef<HTMLElement>({} as HTMLElement);

  const urlWebsocket = getUrl('websocket');
  const websocket = useRef<ReconnectingWebSocket | undefined>();
  const [requestCloseChat, setRequestCloseChat] = useState('');
  const [comment, setComment] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState<SnackbarType>();
  const [showOnHoldHeader, setShowOnHoldHeader] = useState(true);
  const [browserConnection, setBrowserConnection] = useState(true);
  const [discussionId, setDiscussionId] = useState('');
  const [discussionNotification, setDiscussionNotification] = useState<IDiscussionNotification>();
  const [discussionNotificationContent, setDiscussionNotificationContent] =
    useState<IDiscussionNotificationContent | null>(null);
  const [showNotificationMarkedInDiscussion, setShowNotificationMarkedInDiscussion] = useState(false);
  const [discussionUuid, setDiscussionUuid] = useState('');
  const [lastUpdatedAvatar, setLastupdatedAvatar] = useState('');
  const { sendEvent, EVENT_NAMES } = useSegment();

  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const pingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleSnackBar = useCallback(() => {
    setShowSnackbar(!showSnackbar);
  }, [showSnackbar]);

  const handleResultResponse = useCallback(() => {
    if (requestTransferId) {
      setRequestTransferId('');
      setResultTransferRequest(undefined);
    } else {
      setResultMethodCalls(undefined);
    }
  }, [requestTransferId]);

  const executeScroll = useCallback((refDiv?: React.MutableRefObject<HTMLElement>) => {
    const div = refDiv || divRef;
    div.current.scrollTop = div.current.scrollHeight;
  }, []);

  const handlePendingRequests = (request: any) => {
    setPendingRequests((oldState) => [...oldState, request]);
  };

  const handleRemovePendingRequests = (index: number) => {
    setPendingRequests((oldState) => oldState.splice(index, 1));
  };

  const handleUserAvatar = useCallback(
    (etag: string, username: string) => {
      const baseURL = getUrl('http');

      const defaultAvatarURL = `${baseURL}/avatar/${username}?etag=${etag}`;
      if (currentUserInfo.username === username) {
        updateAvatarURL(defaultAvatarURL);
      }
      setLastupdatedAvatar(defaultAvatarURL);
    },
    [currentUserInfo.username, getUrl, updateAvatarURL]
  );

  const keepAliveConnection = useCallback(() => {
    if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
      websocket.current.send(
        JSON.stringify({
          msg: 'ping',
        })
      );

      // Set a timeout for the server response
      pingTimeoutRef.current = setTimeout(() => {
        setWebsocketIsConnect(false);

        // Avoid flooding with queued ping requests if server stops responding
        if (pingIntervalRef.current) {
          clearInterval(pingIntervalRef.current);
          pingIntervalRef.current = null;
        }
      }, pingTimeout);
    }
  }, [websocket, pingTimeoutRef, setWebsocketIsConnect, pingIntervalRef]);

  const setPingInterval = useCallback(() => {
    pingIntervalRef.current = setInterval(keepAliveConnection, pingInterval);
  }, [pingIntervalRef, keepAliveConnection]);

  useEffect(() => {
    if (networkErrorFound) {
      setWebsocketIsConnect(false);
    }
  }, [networkErrorFound]);

  const handleNewNotification = useCallback(
    (notificationParam: any) => {
      const {
        fields: { args = [], eventName = '' },
      } = notificationParam;

      if (args.length) {
        const { payload } = args[0];
        if (payload) {
          const {
            name,
            sender,
            message: { msg = '' },
            rid,
          } = payload;

          // Because RC doesn't provide a better way to identify these messages
          const newChatREGEX = /(new|nuevo|novo) chat/i;
          const isNewConversation = !!name?.match(newChatREGEX);

          if (isNewConversation) {
            setNotification({
              title: `${t('newChat')} - ${sender.username}`,
              description: msg,
              chatId: rid,
              type: NotificationType.NEW_CHAT,
            });

            return;
          }

          if (payload.type === PRIVATE_GROUP) {
            setNotification({
              title: sender.name,
              description: msg,
              chatId: rid,
              type: NotificationType.DISCUSSION,
            });
          }
        } else if (args[0] === 'updated' && args[1].prid === undefined && args.length > 1) {
          const message = args[1];
          if (args[1].lastMessage) {
            message.lastMessage._updatedAt = new Date(message.lastMessage._updatedAt?.$date).toISOString();
            message.lastMessage.ts = new Date(message.lastMessage.ts?.$date).toISOString();
          }

          setSubscribeNotification(message);
        } else if ((args[0] === 'inserted' || args[0] === 'removed') && args.length > 1) {
          setSubscribeNotification({
            ...args[1],
            onHold: false,
          });

          if (args[0] === 'inserted' && eventName.includes(SUBSCRIPTIONS_CHANGED_EVENT)) {
            const { name, rid, t: kind } = args[1];

            const baseNotification = {
              description: '',
              chatId: rid,
              type: NotificationType.NEW_CHAT,
            };

            if (kind === PRIVATE_GROUP) {
              setNotification({ ...baseNotification, title: t('internalDiscussion') });
            } else {
              // New (transferred) chat
              setNotification({ ...baseNotification, title: `${t('newChat')} - ${name}` });
            }
          }
        } else if (args[1].prid !== undefined && args[0] === 'updated') {
          if (args[1].lastMessage && args[1].lastMessage.mentions.length) {
            args[1].lastMessage.mentions.forEach((userMentioned: any) => {
              if (userMentioned._id === currentUserInfo._id && location.pathname.substring(1) !== args[1].prid) {
                setShowNotificationMarkedInDiscussion(true);
                setDiscussionNotificationContent(args[1]);
              }
            });
          } else if (eventName.includes(SUBSCRIPTIONS_CHANGED_EVENT)) {
            setDiscussionNotification(args[1]);

            const title = '';
            const type = NotificationType.DISCUSSION;
            const description = '';
            const notificationAux = {
              title,
              description,
              chatId: args[1].prid,
              type,
            };

            setNotification(notificationAux);
          }
        }
      }
    },
    [currentUserInfo._id, location.pathname, t]
  );

  const handleReadMessage = useCallback(
    (chatId: string) => {
      const messageObj = {
        msg: 'method',
        id: uuidv4().toString(),
        method: 'readMessages',
        params: [chatId],
      };
      if (websocketIsConnect) {
        if (websocket.current) {
          websocket.current.send(JSON.stringify(messageObj));
        }
      } else {
        handlePendingRequests(messageObj);
      }
    },
    [websocketIsConnect]
  );

  const handleHistoryMessages = useCallback(
    (messagesResponse: any) => {
      const { result = {} } = messagesResponse;
      const { messages = [] } = result;

      if (messages.length) {
        const chatMessages: MessageType[] = messages.map((item: MessageResponse) => ({
          id: item._id,
          msg: item.msg,
          md: item.md,
          date: item.ts?.$date && new Date(item.ts.$date),
          from: item.u.name,
          username: item.u.username,
          userId: item.u._id,
          attachments: item.attachments ?? [],
          rid: item.rid,
          t: item.t,
          transferData: item.transferData,
          u: item.u,
          customFields: item.customFields,
        }));

        if (messagesResponse.id === discussionUuid) {
          setMessagesListDiscussion(() =>
            [...chatMessages].sort((a, b) => (a.date.getTime() > b.date.getTime() ? 1 : -1))
          );
          executeScroll();
          setTimeout(() => {
            handleReadMessage(chatMessages[0].rid);
          }, 1000);
        } else {
          // TODO - check this error
          setMessagesList(() => [...chatMessages].sort((a, b) => (a.date.getTime() > b.date.getTime() ? 1 : -1)));
          executeScroll();
          setTimeout(() => {
            if (chatMessages.length > 0) {
              handleReadMessage(chatMessages[0].rid);
            }
          }, 1000);
        }
      }
    },
    [discussionUuid, executeScroll, handleReadMessage]
  );

  const handleNewMessage = useCallback(
    (messageResponse) => {
      const [message] = messageResponse.fields.args;
      const newMessage = {
        id: message._id,
        msg: message.msg,
        md: message.md,
        date: new Date(message.ts.$date),
        from: message.u.name,
        username: message.u.username,
        userId: message.u._id,
        attachments: message.attachments ?? [],
        rid: message.rid,
        t: message.t,
        transferData: message.transferData,
        transferredBy: message.transferredBy,
        transferredTo: message.transferredTo,
        u: message.u,
        customFields: message.customFields,
      };

      const baseNotification = {
        title: newMessage.t,
        description: newMessage.msg,
        chatId: newMessage.id,
      };

      // Triger chats menu re-render by setting a notification
      if (newMessage.t === 'livechat-close') {
        setNotification({ ...baseNotification, type: NotificationType.CHAT_CLOSED });
      } else if (newMessage.t === 'uj') {
        setNotification({ ...baseNotification, type: NotificationType.CHAT_TAKEN });
      } else if (discussionId === newMessage.rid) {
        if (!messagesListDiscussion.find((item) => newMessage.id === item.id)) {
          setMessagesListDiscussion([...messagesListDiscussion, newMessage]);
          executeScroll();
          setTimeout(() => {
            handleReadMessage(newMessage.rid);
          }, 3000);
        }
      } else if (!messagesList.find((item) => newMessage.id === item.id)) {
        setMessagesList([...messagesList, newMessage]);

        handleReadMessage(newMessage.rid);
        executeScroll();
      }
    },
    [discussionId, executeScroll, handleReadMessage, messagesList, messagesListDiscussion, t]
  );

  const handleShowNotificationMarkedInDiscussion = useCallback((value: boolean) => {
    setShowNotificationMarkedInDiscussion(value);
  }, []);

  const connectWebSocket = useCallback(() => {
    if (websocket.current) {
      websocket.current.send(
        JSON.stringify({
          msg: 'connect',
          version: '1',
          support: ['1'],
        })
      );
    }
  }, [websocket]);

  const loginWebSocket = useCallback(
    (token: string) => {
      const loginRequest = {
        msg: 'method',
        method: 'login',
        id: uuidv4().toString(),
        params: [{ resume: token }],
      };
      if (websocket.current) {
        websocket.current.send(JSON.stringify(loginRequest));
      }
    },
    [websocket]
  );

  const unsubscribeRoomMessages = useCallback(
    (roomType: IRoomType) => {
      const emptyArray: MessageType[] = [];

      if (roomType === 'discussion') {
        setMessagesListDiscussion(emptyArray);
      } else {
        setMessagesList(emptyArray);
      }
      if (subscribeIdMessages || subscribeIdMessagesDiscussion) {
        const roomMessagesSub = {
          msg: 'unsub',
          id: roomType === 'chat' ? subscribeIdMessages : subscribeIdMessagesDiscussion,
        };
        if (roomType === 'chat') {
          setSubscribeIdMessages('');
        } else {
          setSubscribeIdMessagesDiscussion('');
        }
        if (websocketIsConnect && websocket.current) {
          websocket.current.send(JSON.stringify(roomMessagesSub));
        } else {
          handlePendingRequests(roomMessagesSub);
        }
      }
    },
    [subscribeIdMessages, subscribeIdMessagesDiscussion, websocket, websocketIsConnect]
  );

  const listeningRoomMessages = useCallback(
    (room: string, roomType: IRoomType) => {
      // TODO: check if would we need to also unsubscribe when `roomType` is 'inquiry'?
      if (roomType === 'chat') unsubscribeRoomMessages('chat');
      const roomMessagesSub = {
        msg: 'sub',
        id: uuidv4().toString(),
        name: 'stream-room-messages',
        params: [room, false],
      };
      if (roomType === 'chat') {
        setSubscribeIdMessages(roomMessagesSub.id);
      } else if (roomType === 'discussion') {
        setSubscribeIdMessagesDiscussion(roomMessagesSub.id);
        setDiscussionId(room);
      }

      if (websocketIsConnect && websocket.current) {
        websocket.current.send(JSON.stringify(roomMessagesSub));
      } else {
        handlePendingRequests(roomMessagesSub);
      }
    },
    [unsubscribeRoomMessages, websocketIsConnect]
  );

  const loadHistoryMessages = useCallback(
    (room: string, type: string) => {
      const loadMessages = {
        msg: 'method',
        method: 'loadHistory',
        id: uuidv4().toString(),
        params: [room, null, 100, { $date: new Date().getTime() }],
      };

      if (type === 'discussion') {
        setDiscussionUuid(loadMessages.id);
      }
      if (websocketIsConnect && websocket.current) {
        websocket.current.send(JSON.stringify(loadMessages));
      } else {
        handlePendingRequests(loadMessages);
      }
    },
    [websocket, websocketIsConnect]
  );

  const sendMessage = useCallback(
    (message: string, room: string) => {
      if (message) {
        const messageObj = {
          msg: 'method',
          method: 'sendMessage',
          id: uuidv4().toString(),
          params: [
            {
              _id: uuidv4().toString(),
              rid: room,
              msg: message,
            },
          ],
        };
        if (websocket.current) {
          websocket.current.send(JSON.stringify(messageObj));
        }
      }
    },
    [websocket]
  );

  const streamNotifyLogged = (event: string) => {
    const statusObj = {
      msg: 'sub',
      id: uuidv4().toString(),
      name: 'stream-notify-logged',
      params: [event, false],
    };
    if (websocket.current) {
      websocket.current.send(JSON.stringify(statusObj));
    }
  };

  const streamNotifyUser = (userId: string, event: string) => {
    const messageObj = {
      msg: 'sub',
      id: uuidv4().toString(),
      name: 'stream-notify-user',
      params: [`${userId}/${event}`, false],
    };
    if (websocket.current) {
      websocket.current.send(JSON.stringify(messageObj));
    }
  };

  const handleResumeOnHoldChat = useCallback(
    async (chatId: string) => {
      try {
        await resumeOnHold(chatId);

        setShowOnHoldHeader(true);
        unsubscribeRoomMessages('chat');
        navigate(`/${chatId}`, { replace: true });
      } catch (error) {
        logError(`Error resuming chat. Room id: ${chatId}`, error);
      }
    },
    [navigate, unsubscribeRoomMessages]
  );

  const returnToQueue = useCallback(
    async (chatId: string, userToken?: string) => {
      try {
        await putChatOnHoldService(chatId);
        const response = await sendEmptyMessageFromUser(chatId, userToken || '');
        return response;
      } catch (error) {
        logError(`Error putting chat on hold. Room id: ${chatId}`, error);
        await resumeOnHold(chatId);
        return { data: { success: false } };
      }
    },
    [websocket]
  );

  const logout = useCallback(() => {
    const changeObj = {
      msg: 'method',
      id: uuidv4().toString(),
      method: 'logout',
      params: [],
    };

    if (websocket.current) {
      websocket.current.send(JSON.stringify(changeObj));
    }
    sendEvent(EVENT_NAMES.USER_LOGOUT);
  }, [EVENT_NAMES.USER_LOGOUT, sendEvent, websocket]);

  const livechatSaveInfoRoom = useCallback(
    (chatId: string, visitorId: string, tags: string[], ticket?: string) => {
      const changeObj = {
        msg: 'method',
        id: uuidv4().toString(),
        method: 'livechat:saveInfo',
        params: [
          { _id: visitorId },
          {
            _id: chatId,
            topic: '',
            tags,
            livechatData: {
              departmentName: '',
              userKey: '',
              putInHoldCause: '',
              rating: '',
              ratingReason: '',
              ticket,
              userSessionId: '',
              queuedUp: '',
              botId: '',
            },
          },
        ],
      };

      if (websocket.current) {
        websocket.current.send(JSON.stringify(changeObj));
      }
    },
    [websocket]
  );

  const authAndConnectWebsocket = useCallback(() => {
    const token = sessionStorage.getItem(SESSION_STORAGE_KEYS.AUTH_TOKEN)?.toString();
    const userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.USER_ID)?.toString();
    if (token) {
      loginWebSocket(token);
    }
    if (userId) {
      streamNotifyUser(userId, 'notification');
      streamNotifyUser(userId, 'subscriptions-changed');
      streamNotifyUser(userId, 'rooms-changed');
      streamNotifyLogged('user-status');
      streamNotifyLogged('updateAvatar');
    }
  }, [loginWebSocket]);

  const handleErrorNewMessage = useCallback((message?: MessageType) => {
    if (message) {
      setMessagesList((oldState) => [...oldState, message]);
    } else {
      setMessagesList((oldState) => oldState.filter((item) => !item.hasError));
    }
  }, []);

  useEffect(() => {
    window.addEventListener('offline', () => {
      setBrowserConnection(false);
    });

    window.addEventListener('online', () => {
      setBrowserConnection(true);
    });

    const instance = getInstance();
    sessionStorage.setItem(SESSION_STORAGE_KEYS.PATH, instance);

    websocket.current = new ReconnectingWebSocket(urlWebsocket, undefined, {
      minReconnectionDelay: MIN_RECONNECTION_DELAY_TIME,
      minUptime: MIN_UP_TIME,
    });

    if (websocket.current) {
      websocket.current.onerror = () => {
        setWebsocketIsConnect(false);
      };

      websocket.current.onopen = () => {
        connectWebSocket();
        authAndConnectWebsocket();
        logInfo(WEBSOCKET_EVENT_LOG_MESSAGE.STATUS_CHANGE, {
          open: true,
          source: 'rocketChat',
        });
      };
      websocket.current.onclose = (event) => {
        setWebsocketIsConnect(false);
        logInfo(WEBSOCKET_EVENT_LOG_MESSAGE.STATUS_CHANGE, {
          ...event,
          open: false,
          source: 'rocketChat',
        });
      };
    }

    return () => {
      if (websocket.current?.readyState === WebSocket.OPEN) {
        websocket.current.close();
      }
    };
  }, [authAndConnectWebsocket, connectWebSocket, getInstance, urlWebsocket]);

  useEffect(() => {
    setPingInterval();

    const clearIntervalTimeoutAndCloseSocket = () => {
      if (websocket.current?.readyState === WebSocket.OPEN) {
        websocket.current.close();
      }
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
      }
      if (pingTimeoutRef.current) {
        clearTimeout(pingTimeoutRef.current);
      }
    };

    return clearIntervalTimeoutAndCloseSocket;
  }, [setPingInterval]);

  useEffect(() => {
    if (websocketIsConnect) {
      pendingRequests.forEach((item) => {
        if (websocket.current) websocket.current.send(JSON.stringify(item));

        if (isPendingRequestItem(item) && isPendingRequestIndex(item.id)) {
          handleRemovePendingRequests(item.id);
        }
      });
    }
  }, [pendingRequests, websocketIsConnect]);

  useEffect(() => {
    if (websocket.current) {
      websocket.current.onmessage = (e) => {
        if (!pingIntervalRef.current) {
          // When server `pong` responses were previously timing out
          setWebsocketIsConnect(true);
          setPingInterval();
        }

        const response = JSON.parse(e.data);
        if (response.error && response.error.error === 403) {
          sessionStorage.clear();
          navigate('/login');
        }

        if (response.msg === 'pong') {
          clearTimeout(pingTimeoutRef.current!); // Clear the ping timeout
          return;
        }
        if (response.msg === 'connected') {
          setWebsocketHasConnected(true);
          setWebsocketIsConnect(true);
          setNetworkErrorFound(false);
          return;
        }

        if (response.msg === 'changed' && response.collection === 'stream-notify-logged') {
          if (response.fields.args.length && response.fields.eventName === 'updateAvatar') {
            const { etag = '', username = '' } = response.fields.args[0];
            handleUserAvatar(etag, username);
            return;
          }
        }

        if (response.msg === 'changed' && response.collection === 'stream-notify-user') {
          handleNewNotification(response);
          return;
        }

        if (response.msg === 'changed' && response.collection === 'stream-room-messages') {
          handleNewMessage(response);
          return;
        }

        if (response.msg === 'result') {
          if (response.id === requestTransferId) {
            setResultTransferRequest(response);
          }
          if (response.id === requestCloseChat) {
            setResultMethodCalls(response);
            setRequestCloseChat('');
            if (response?.result) {
              setShowSnackbar(true);
              setSnackBarMessage('ChatFinishSuccess');
            }
          }

          handleHistoryMessages(response);
        }
      };
    }
  }, [
    handleHistoryMessages,
    handleNewMessage,
    handleNewNotification,
    handleUserAvatar,
    navigate,
    requestCloseChat,
    requestTransferId,
    setNetworkErrorFound,
    setPingInterval,
  ]);

  useEffect(() => {
    if (snackBarMessage) setShowSnackbar(true);
  }, [snackBarMessage]);

  const memoizedValues = useMemo(
    () => ({
      sendMessage,
      connectWebSocket,
      websocketIsConnect,
      websocketHasConnected,
      listeningRoomMessages,
      loadHistoryMessages,
      loginWebSocket,
      handleResumeOnHoldChat,
      messagesList,
      divRef,
      notification,
      subscribeNotification,
      returnToQueue,
      resultMethodCalls,
      handleResultResponse,
      unsubscribeRoomMessages,
      livechatSaveInfoRoom,
      showSnackbar,
      comment,
      setComment,
      logout,
      resultTransferRequest,
      handleSnackBar,
      snackBarType,
      setSnackBarType,
      snackBarMessage,
      setSnackBarMessage,
      showOnHoldHeader,
      browserConnection,
      executeScroll,
      messagesListDiscussion,
      discussionNotification,
      setDiscussionNotification,
      showNotificationMarkedInDiscussion,
      discussionNotificationContent,
      handleShowNotificationMarkedInDiscussion,
      handleErrorNewMessage,
      lastUpdatedAvatar,
    }),
    [
      sendMessage,
      connectWebSocket,
      websocketIsConnect,
      websocketHasConnected,
      listeningRoomMessages,
      loadHistoryMessages,
      loginWebSocket,
      handleResumeOnHoldChat,
      messagesList,
      divRef,
      notification,
      subscribeNotification,
      returnToQueue,
      resultMethodCalls,
      handleResultResponse,
      unsubscribeRoomMessages,
      livechatSaveInfoRoom,
      showSnackbar,
      comment,
      setComment,
      logout,
      resultTransferRequest,
      handleSnackBar,
      snackBarType,
      setSnackBarType,
      snackBarMessage,
      setSnackBarMessage,
      showOnHoldHeader,
      browserConnection,
      executeScroll,
      messagesListDiscussion,
      discussionNotification,
      setDiscussionNotification,
      showNotificationMarkedInDiscussion,
      discussionNotificationContent,
      handleShowNotificationMarkedInDiscussion,
      handleErrorNewMessage,
      lastUpdatedAvatar,
    ]
  );

  return <WebsocketContext.Provider value={memoizedValues}>{children}</WebsocketContext.Provider>;
};

export default WebsocketProvider;
