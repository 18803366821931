import axios, { AxiosInstance, AxiosError, InternalAxiosRequestConfig } from 'axios';
import { environmentUtils } from 'utils';
import { SESSION_STORAGE_KEYS } from 'constants/defaultValues';
import { logError } from 'services/Logger';
import paramsSerializer from './AxiosParamsSerializer';

const AxiosInstanceBFF: AxiosInstance = axios.create({
  paramsSerializer,
  headers: { 'Content-type': 'application/json' },
  baseURL: `${process.env.REACT_APP_BFF_API_URL}`,
});

AxiosInstanceBFF.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const authToken = sessionStorage.getItem(SESSION_STORAGE_KEYS.AUTH_TOKEN);
    const userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.USER_ID);
    const instance = environmentUtils.getEnvironmentInstance();

    if (authToken && userId && instance) {
      config.headers.set({ 'X-User-Id': userId });
      config.headers.set({ 'X-Auth-Token': authToken });
      config.headers.set({ 'X-Instance': instance });
    }
    return config;
  },
  (error: AxiosError) => {
    logError('AxiosRequestError', error);

    return Promise.reject(error);
  }
);

export default AxiosInstanceBFF;
