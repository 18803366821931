export const MILLIS_IN_ONE_DAY = 1000 * 60 * 60 * 24;

export const CONVERSATION_EXPLORER_DAYS_RANGE = 14;

export const DISABLED_SUFFIX = '-disabled';

export enum SortDirection {
  ASC = 1,
  DESC = -1,
}

export const closeChatSuffixes = ['$end', '$finalizar'];

export const samlRoutes = {
  login: '/saml/login',
  callback: '/saml/callback',
};
