import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SESSION_STORAGE_KEYS } from '../../constants/defaultValues';
import useLogin from '../login/useLogin';

export const SAMLCallback: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { recordLogin } = useLogin();

  useEffect(() => {
    const token = searchParams.get('token');
    const userId = searchParams.get('userId');

    const hasRequiredParams = token && userId;

    if (hasRequiredParams) {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.AUTH_TOKEN, token);
      sessionStorage.setItem(SESSION_STORAGE_KEYS.USER_ID, userId);
      recordLogin().then(() => navigate('/'));
    } else {
      navigate('/login');
    }
  }, [searchParams, navigate, recordLogin]);

  return <p>{t('authenticating')}</p>;
};
