import React, { useContext, useMemo } from 'react';
import { Stack, Typography, useMediaQuery, Theme, Icon } from '@mui/material';
import { Button, IconButton, TextField, Tooltip } from '@engyalo/design-system';
import { useTranslation } from 'react-i18next';
import useInputMessage from './useInputMessage';
import { MicButton, QuickMessageTagIcon } from './style';
import { ModalErrorPermission } from './modalErrorPermission';
import CannedMessagesContext from '../../../../../contexts/cannedMessages/context';
import SelectAgentContext from '../../../../../contexts/selectAgentContext/context';
import { AttachFile } from '../../../AttachFile';
import { SendMessage } from '../../hooks/useChat';

export interface IInputMessage {
  discussionId: string;
  isDiscussion: boolean;
  showQuickMessagesIcon: boolean;
  isWsConnected?: boolean;
  sendMessage?: SendMessage;
  isChatLoading?: boolean;
}

const InputMessage: React.FC<IInputMessage> = (props) => {
  const { discussionId, isDiscussion, showQuickMessagesIcon, isWsConnected, isChatLoading, sendMessage } = props;
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    currentMessage,
    setCurrentMessage,
    handleSendMessage,
    startRecordingAudio,
    sendAudio,
    cancelAudio,
    timerRecoder,
    isRecording,
    openErrorPermission,
    setErrorPermission,
    translation,
    setAlignment,
    changeSendButtonAlignment,
    placeholderInput,
    disabledInputChat,
    focusDiv,
    discussionOpened,
    currentMessageDiscussion,
    setCurrentMessageDiscussion,
    userIsInDiscussion,
    disableInput,
    setDisableInput,
    selectedCannedMessageTags,
    handleForceAddTagsFromCannedMessage,
    clearSelectedCannedMessage,
  } = useInputMessage(discussionId, isDiscussion, sendMessage);

  const isInputDisabled = isChatLoading || disableInput;

  const { handleQuickMessages, filterCannedMessages, handleMessageSelection, showQuickMessages } =
    useContext(CannedMessagesContext);
  const { handleSelectAgent, filterAgents, showSelectAgent } = useContext(SelectAgentContext);
  useMemo(() => {
    const checkInputIsEmpty = isDiscussion ? !currentMessageDiscussion : !currentMessage;
    setDisableInput(checkInputIsEmpty);
  }, [isDiscussion, currentMessageDiscussion, currentMessage]);

  return (
    <>
      <Stack
        direction="row-reverse"
        width="100%"
        alignItems="flex-end"
        data-testid="inputMessage"
        sx={{ margin: '8px 0px' }}
      >
        <TextField
          inputRef={focusDiv}
          id="messageInput"
          minRows={1}
          maxRows={5}
          multiline
          disabled={discussionOpened && userIsInDiscussion ? false : disabledInputChat}
          placeholder={translation(placeholderInput)}
          value={isDiscussion ? currentMessageDiscussion : currentMessage}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            if (!currentMessage && value === '!' && !isDiscussion) {
              handleQuickMessages();
            }
            if (isDiscussion) {
              setCurrentMessageDiscussion(value);
            } else {
              setCurrentMessage(value);
              clearSelectedCannedMessage();
            }

            if (isDiscussion && showSelectAgent && value) {
              filterAgents(value);
            }
            if (showQuickMessages) {
              filterCannedMessages(value);
            }
          }}
          onKeyDown={(event) => {
            if (event.key === '@') {
              handleSelectAgent?.(); // TODO - this is not working
            }
            if (event.key === 'Tab') {
              handleMessageSelection(true);
            }
            if (event.key === 'Enter' && !event.shiftKey) {
              event.preventDefault();
            }
          }}
          onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter' && !event.shiftKey) {
              const { value } = event.target as HTMLInputElement;

              if (isWsConnected && !isInputDisabled) {
                handleSendMessage(value);
              }
            }
            setAlignment(changeSendButtonAlignment());
          }}
          sx={{
            order: 1,
            flex: 1,
            display: isDiscussion && isRecording ? 'none' : 'block',
            '& .MuiInputBase-input': {
              overflow: 'auto',
              textOverflow: 'ellipsis',
            },
            '& .MuiInputBase-root': {
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
          inputProps={{ 'data-testid': 'inputMessageText' }}
          InputProps={{
            endAdornment: selectedCannedMessageTags.length > 0 && (
              <Tooltip
                title={`${t('quickMessagesTags', {
                  numberOfTags: selectedCannedMessageTags.length,
                })}`}
                placement="top"
              >
                <QuickMessageTagIcon className="fa-regular fa-tags" />
              </Tooltip>
            ),
          }}
        />

        {!currentMessage && !currentMessageDiscussion ? (
          <div>
            {isRecording ? (
              <Stack direction="row" alignItems="center">
                <IconButton
                  onClick={() => cancelAudio()}
                  variant="text"
                  color="error"
                  sx={{
                    marginLeft: '8px',
                  }}
                >
                  <i className="fa-regular fa-circle-xmark fa-sm" />
                </IconButton>
                <Icon
                  className="fa-solid fa-circle fa-sm"
                  sx={{
                    color: 'neutral.var70',
                    margin: '0px 10px',
                  }}
                />

                <Typography sx={{ width: '50px' }}>{timerRecoder}</Typography>
                <IconButton
                  data-testid="sendButton"
                  onClick={() => sendAudio()}
                  variant="text"
                  color="success"
                  sx={{
                    marginRight: '8px',
                  }}
                >
                  <i className="fa-regular fa-circle-check fa-sm" />
                </IconButton>
              </Stack>
            ) : (
              <Stack direction="row" alignItems="flex-end" gap="4px">
                {isMobile && (
                  <>
                    <AttachFile disabled={userIsInDiscussion ? false : disabledInputChat} sendMessage={sendMessage} />

                    <IconButton
                      disabled={disabledInputChat}
                      sx={{
                        display: showQuickMessagesIcon ? 'flex' : 'none',
                      }}
                      color={showQuickMessages ? 'primary' : 'neutral2'}
                      onClick={handleQuickMessages}
                      variant="text"
                    >
                      <Tooltip title={`${t('quickMessages')}`} placement="top">
                        <i className="fa-regular fa-square-bolt" />
                      </Tooltip>
                    </IconButton>
                  </>
                )}

                <Tooltip title={`${t('recordVoiceMessage')}`} placement="top">
                  <MicButton
                    disabled={discussionOpened ? false : disabledInputChat}
                    onClick={() => startRecordingAudio()}
                    variant="text"
                    color="neutral2"
                  >
                    <i className="fa-solid fa-microphone fa-sm" />
                  </MicButton>
                </Tooltip>
              </Stack>
            )}
          </div>
        ) : (
          <Stack direction="row" alignItems="flex-end" gap="4px">
            {isMobile && (
              <AttachFile disabled={userIsInDiscussion ? false : disabledInputChat} sendMessage={sendMessage} />
            )}
            <Button
              data-testid="sendMessageBtn"
              onClick={() => {
                handleSendMessage(isDiscussion ? currentMessageDiscussion : currentMessage);
                handleForceAddTagsFromCannedMessage();
              }}
              variant="text"
              disabled={disabledInputChat || !isWsConnected || isInputDisabled}
              sx={{
                width: '70px',
              }}
              startIcon={<i className="fa-sharp fa-solid fa-paper-plane-top" />}
            />
          </Stack>
        )}
      </Stack>

      <ModalErrorPermission openErrorPermission={openErrorPermission} setErrorPermission={setErrorPermission} />
    </>
  );
};

export default InputMessage;
