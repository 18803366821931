import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import updateHtmlFontSize from 'utils/updateHtmlFontSize';
import { FontSizeOptions, Preferences } from '../../types/user';
import PreferencesContext from './context';
import AuthContext from '../auth/context';
import { STORAGE_KEYS } from '../../constants/defaultValues';
import useEnvironment from '../../hooks/useEnvironments';

const PreferencesProvider: React.FC = (props: any) => {
  const { children } = props;
  const { t } = useTranslation();
  const { getBasePath, getInstance } = useEnvironment();
  const instance = useMemo(() => getInstance(), [getInstance]);
  const basePath = useMemo(
    () => (process.env.REACT_APP_EPHEMERAL ? getBasePath().replace(instance, '') : '/'),
    [getBasePath, instance]
  );
  const fontSizeLocalStorage = localStorage.getItem(STORAGE_KEYS.FONTSIZE) as FontSizeOptions | null;
  const languageLocalStorage = localStorage.getItem(STORAGE_KEYS.LANGUAGE);
  const { currentUserInfo } = useContext(AuthContext);
  const [userPreferences, setPreferences] = useState<Preferences>({} as Preferences);
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [maxNumberSimultaneousChat, setmaxNumberSimultaneousChat] = useState<string>('');
  const [globalFontSize, setGlobalFontSize] = useState(fontSizeLocalStorage || FontSizeOptions.default);
  const [disable, setDisable] = useState(false);

  const handleSelectLanguage = useCallback(
    (language: string) => {
      setSelectedLanguage(language);
      localStorage.setItem(STORAGE_KEYS.LANGUAGE, language);
      if (languageLocalStorage !== language) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    },
    [languageLocalStorage]
  );

  const updateGlobalFontSize = useCallback(
    (fontSize?: FontSizeOptions, draft?: boolean) => {
      const newFontSize = fontSize || fontSizeLocalStorage;

      if (!newFontSize) {
        return;
      }

      updateHtmlFontSize(fontSize, globalFontSize);
      setGlobalFontSize(newFontSize);

      if (!draft) {
        localStorage.setItem(STORAGE_KEYS.FONTSIZE, newFontSize || FontSizeOptions.default);
      }
    },
    [globalFontSize, fontSizeLocalStorage]
  );

  const playSound = useCallback(() => {
    const audio = new Audio(`${basePath}sounds/${userPreferences.newMessageNotification}.mp3`);
    audio.play();
  }, [basePath, userPreferences.newMessageNotification]);

  const notify = useCallback(() => {
    const testNotification = new Notification(`${t('newChat')}/${t('message')}`, {
      body: `${t('testMessage')}`,
      icon: `${basePath}favicon.png`,
    });

    testNotification.addEventListener('click', () => window.focus());
  }, [basePath, t]);

  useEffect(() => {
    if (currentUserInfo._id) {
      const { settings } = currentUserInfo;
      const { preferences } = settings;
      setPreferences(preferences);
      setSelectedLanguage(preferences.language);
      setmaxNumberSimultaneousChat(currentUserInfo.livechat?.maxNumberSimultaneousChat ?? '');
    }
  }, [currentUserInfo]);

  const value = useMemo(
    () => ({
      playSound,
      notify,
      userPreferences,
      loading,
      selectedLanguage,
      maxNumberSimultaneousChat,
      handleSelectLanguage,
      disable,
      setLoading,
      setDisable,
      globalFontSize,
      updateGlobalFontSize,
    }),
    [
      playSound,
      notify,
      userPreferences,
      loading,
      selectedLanguage,
      maxNumberSimultaneousChat,
      handleSelectLanguage,
      disable,
      setLoading,
      setDisable,
      globalFontSize,
      updateGlobalFontSize,
    ]
  );

  return <PreferencesContext.Provider value={value}>{children}</PreferencesContext.Provider>;
};

export default PreferencesProvider;
