export enum STORAGE_KEYS {
  BOT_DEPARTMENT_NAME = 'bot',
  DEFAULT_UNIT = 'Geral',
  TIMEOUT = 'timeout',
  LANGUAGE = 'language',
  FONTSIZE = 'globalFontSize',
}

export enum SESSION_STORAGE_KEYS {
  AUTH_TOKEN = 'authToken',
  APP_VERSION = 'appVersion',
  DASHBOARD_SELECTED_TEAMS = 'dashboardSelectedTeams',
  MANUAL_OFFLINE = 'manualOffline',
  PATH = 'path',
  ROLES = 'roles',
  USERNAME = 'username',
  USER_ID = 'userId',
}

export const userflowLanguageCodeMap: Record<string, string> = {
  en: 'en-US',
  es: 'es-ES',
  'pt-BR': 'pt-BR',
};

export function getLanguage() {
  const LANGUAGE = localStorage.getItem(STORAGE_KEYS.LANGUAGE) || window.navigator.language;
  return LANGUAGE;
}

export function formatDate(date: string, language: string): string {
  return new Date(date).toLocaleTimeString([language], {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
}

export enum DASHBOARD_ANALYTICS_KEYS {
  YALO_SYSTEM_USER_ADMIN = 'yalo-system',
  LIVECHAT_AGENT_ROLE = 'livechat-agent',
  DATE_FORMAT_DAY_MONTH_YEAR_BR = 'dd/MM/yyyy',
  DATE_FORMAT_DAY_MONTH_YEAR_USA = 'MM/dd/yyyy',
  ENGLISH = 'en',
}

export enum BROWSER_NOTIFICATION {
  SOUND_VOLUME = 'soundVolume',
  NEW_ROOM_NOTIFICATION_SOUND = 'newRoomNotificationSound',
  NEW_MESSAGE_NOTIFICATION_SOUND = 'newMessageNotificationSound',
}

export enum TypeDescriptionTag {
  ROOM = 'room',
  VISITOR = 'visitor',
}

export enum WEBSOCKET_EVENT_LOG_MESSAGE {
  STATUS_CHANGE = 'WEBSOCKET_STATE_CHANGE',
  RECONNECTION_RC = 'WEBSOCKET_RECONNECTION_RC',
  RECONNECTION_BFF = 'WEBSOCKET_RECONNECTION_BFF',
}

export const MAX_CHATS_IN_ACCORDION = 25;
export const CHAT_RETENTION_DAYS = '90';
export const HIDDEN_TAGS = ['left-queue'];
export const HIDDEN_DEPARTMENTS = ['bot'];
export const HIDDEN_UNITS = ['system'];
export const YALO_ADMIN = 'yalo-system';
